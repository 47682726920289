<template>
  <nav class="navbar mb-1">
    <ul class="navbar-list">
      <li class="navbar-item">
        <router-link class="button" :class="activeClass(routesNewsletter)" :to="{ name:'owners-newsletter'}">Newsletter</router-link>
      </li>
      <li class="navbar-item">
        <router-link class="button" :class="activeClass(routesHotelPerformance)" :to="{name:'hotel-performance'}">Hotel performance</router-link>
      </li>
      <li class="navbar-item" v-if="can('fivesclub_web_owners_show_the_fives_vacations_promos_list')">
        <router-link class="button" :class="activeClass(routesFivesVacations)" :to="{name:'fives-vacations'}"> The Fives Vacations</router-link>
      </li>
      <li class="navbar-item" v-if="can('fivesclub_web_owners_show_staff_contacts_list')">
        <router-link class="button" :class="activeClass(routesFivesClubStaff)" :to="{name:'staff-contact'}">Staff contact</router-link>
      </li>
      <li class="navbar-item" v-if="can('fivesclub_web_owners_show_owners_general_documents_list')">
        <router-link class="button" :class="activeClass(routesGeneralDocuments)" :to="{name:'general-documents'}">General documents</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  data(){
    return {
      routesNewsletter: ['owners-newsletter','create-newsletter', 'edit-newsletter'],
      routesHotelPerformance: ['hotel-performance','create-hotel-performance', 'edit-hotel-performance'],
      routesFivesVacations: ['fives-vacations','create-fives-vacations', 'edit-fives-vacations'],
      routesFivesClubStaff: ['staff-contact','create-staff-contact', 'edit-staff-contact'],
      routesGeneralDocuments: ['general-documents','create-general-document', 'edit-general-document'],
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {
    activeClass(routes) {
      return routes.includes(this.$route.name) ? 'active' : ''
    }
  },
};
</script>

<style scoped>
.navbar {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px 0 rgba(#22292f, 0.14);

}

.dark-layout .navbar {
  background-color: #243447;
}

.dark-layout .navbar a {
  color: #fbfbfb;
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.navbar-item {
  margin-right: 10px;
}

.navbar-item a {
  /* color: #111111; */
  text-decoration: none;
}

@media (max-width: 767px) {
  .navbar-list {
    flex-direction: column;
  }

  .navbar-item {
    margin-bottom: 10px;
  }
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: #000;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  transition: background-color 0.3s ease;
}


.button:hover, .button.active {
  background-color: #333366;
  color: #fff;

}

</style>